<template>
  <!-- TEXT COMPONENT -->
  <div v-if="isVisible && attribute" :class="classObject" :data-id="filter.alias">
    <pui-input
      v-if="[AttributeTypes.STRING, AttributeTypes.LABEL].includes(attribute.type)"
      ref="attributeField"
      :label="piivoTranslate(filter)"
      :value="value"
      :minLength="minLength"
      :disabled="isDisabled"
      type="text"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    />
    <!-- NUMBER COMPONENT : Type numeric without links -->
    <pui-input
      v-else-if="
        attribute.type === AttributeTypes.NUMERIC &&
        (attribute.parameters == null || attribute.parameters.links == null)
      "
      ref="attributeField"
      :label="piivoTranslate(filter)"
      :value="value"
      :min="min"
      :max="max"
      :step="step"
      :disabled="isDisabled"
      :formatter="numberFormatter"
      :lazyFormatter="true"
      type="number"
      @input="onInput"
      @change="onChange"
      @blur="onBlur"
    />

    <!-- DATE COMPONENT -->
    <div v-else-if="attribute.type === AttributeTypes.DATE">
      <label v-if="isRange" class="input-date-label">{{ piivoTranslate(filter) }}</label>
      <pui-input
        ref="attributeField"
        :label="isRange === false ? piivoTranslate(filter) : $t('common.filters.date_range.from')"
        :value="startDateValue"
        :disabled="isDisabled"
        type="date"
        @change="onDateChange($event, endDateValue, true)"
      />
      <pui-input
        v-if="isRange"
        :label="$t('common.filters.date_range.to')"
        :value="endDateValue"
        :disabled="isDisabled"
        type="date"
        @change="onDateChange(startDateValue, $event, false)"
      />
    </div>

    <!-- LIST COMPONENT : Type Links or orther type with links -->
    <pui-common-select
      v-else-if="isComboListFilter"
      ref="attributeField"
      :label="piivoTranslate(filter)"
      :display="piivoTranslate"
      :value="value"
      :options="filteredOptions"
      :taggable="isMultiSelect"
      :multiple="isMultiSelect"
      :allowGrouping="allowGrouping"
      :disabled="isDisabled"
      :isLoading="isLoadingOptions"
      :showNoOptions="true"
      @listUpdated="onListUpdated"
      @selectionCleared="onListUpdated"
      @blur="onBlurList"
      @open="onOpenList"
    >
      <template #noOptions>
        <no-options
          :isLoadError="hasOptionsError"
          :noOptionsMessage="
            filter.attribute.options ? filter.attribute.options.noOptionsMessage : null
          "
          :loadOptions="loadOptions"
        ></no-options>
      </template>
    </pui-common-select>
    <pui-common-select
      v-else-if="attribute.type === AttributeTypes.BOOLEAN"
      ref="attributeField"
      :label="piivoTranslate(filter)"
      :value="value"
      :options="[true, false]"
      :display="displayBoolean"
      :taggable="false"
      :multiple="false"
      :disabled="isDisabled"
      @select="onChange"
      @selectionCleared="onChange(null)"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import Vue from 'vue';

import helpers from '../../../../core/helpers';
import services from '../../../../core/services';
import { getFormattingManager } from '../../../../core/services/formattingManager';
import { AttributeTypes } from '../../constants';
import { identifiersToLinkOptions } from '../../helpers/formsHelper';
import { filterByScope } from '../../helpers/scopeHelper';
import noOptions from './NoOptions.vue';

export default {
  name: 'PuiAttributeFilterPanel',
  components: {
    noOptions,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    filter: {
      required: true,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Scope to filter options for Links attributes
     */
    scopeFilter: {
      type: String,
      default: null,
    },
    /**
     * Load function for Select options.
     *
     * @type {(filter: object, options: { context?: object }) => Promise<object[]|null>}
     */
    loadOptionsFunction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      AttributeTypes: AttributeTypes,

      // Select options
      options: [],
      isLoadingOptions: false,
      hasOptionsError: false,
      loadOptionsPromise: null,
    };
  },
  computed: {
    /**
     * Class object for container (div class).
     */
    classObject() {
      return ['pui-attribute-filter-panel'];
    },
    /**
     * Get the filter attribute.
     */
    attribute() {
      return this.filter.attribute;
    },
    /**
     * Is filter panel visible (boolean).
     */
    isVisible() {
      return !this.filter.options || this.filter.options.visible !== false;
    },
    /**
     * Is filter panel disabled (boolean).
     */
    isDisabled() {
      return this.disabled || (this.filter.options && this.filter.options.enabled === false);
    },
    /**
     * @returns {boolean} if the filter corresponds to a combo list filter
     */
    isComboListFilter() {
      return (
        this.attribute.type === AttributeTypes.LINKS ||
        (this.attribute.parameters != null && this.attribute.parameters.links != null)
      );
    },
    isMultiSelect() {
      return this.filter.options && this.filter.options.multiSelect === true;
    },
    /**
     * Is a range date filter.
     */
    isRange() {
      return this.filter.options && this.filter.options.range === true;
    },
    /**
     * Get minimum text length.
     */
    minLength() {
      return this.attribute.options ? this.attribute.options.minimumLength : null;
    },
    /**
     * Get maximum text length.
     */
    maxLength() {
      const val = this.attribute.options ? this.attribute.options.maximumLength : null;
      // Interpret '0' as null
      return val === 0 ? null : val;
    },
    /**
     * Get minimum value (numeric input).
     */
    min() {
      return this.attribute.options ? this.attribute.options.minimum : null;
    },
    /**
     * Get maximum value (numeric input).
     */
    max() {
      return this.attribute.options ? this.attribute.options.maximum : null;
    },
    /**
     * Get step (numeric input).
     */
    step() {
      return this.attribute.options ? this.attribute.options.step : null;
    },
    /**
     * Allow grouping flag (for filter links list).
     */
    allowGrouping() {
      return this.filter.options && this.filter.options.allowGrouping === true;
    },
    /**
     * Start date (for date filter)
     */
    startDateValue() {
      if (this.attribute && this.attribute.type === AttributeTypes.DATE && this.value) {
        if (this.isRange) {
          return this.value.length > 0 ? this.value[0] : null;
        } else {
          return this.value;
        }
      }

      return null;
    },
    /**
     * End date (for range date filter only)
     */
    endDateValue() {
      if (
        this.attribute &&
        this.attribute.type === AttributeTypes.DATE &&
        this.isRange &&
        this.value
      ) {
        return this.value.length === 2 ? this.value[1] : null;
      }

      return null;
    },
    /**
     * @returns {object[]} array of options filtered for the current scope
     */
    filteredOptions() {
      const res = filterByScope(this.scopeFilter, JSON.parse(JSON.stringify(this.options)));
      res.forEach((el) => {
        if (el.children && Array.isArray(el.children)) {
          el.children = filterByScope(this.scopeFilter, el.children);
        }
      });
      return res;
    },
    /**
     * @returns {object[]} the value as option objects
     */
    linkValue() {
      return identifiersToLinkOptions(this.value, this.filteredOptions);
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    displayBoolean(value) {
      return Vue.filter('displayBoolean')(value);
    },
    /**
     * Input event.
     * @param {Object} newValue : New filter value
     */
    onInput(newValue) {
      if (this.attribute) {
        this.$emit('valueInput', this.filter, newValue, this.getFilterOperator());
      }
    },
    /**
     * Change event.
     * @param {Object} newValue : New filter value
     */
    onChange(newValue) {
      if (this.attribute) {
        this.$emit('valueChanged', this.filter, newValue, this.getFilterOperator());
      }
    },
    /**
     * Blur event.
     * @param {Object} value : Filter value
     */
    onBlur(value) {
      if (this.attribute) {
        this.$emit('blur', this.filter, value, this.getFilterOperator());
      }
    },
    /**
     * List updated event for select.
     * @param {Object} values : filter values
     */
    onListUpdated(values) {
      this.onChange(this.getNormalizedValues(values));
    },
    /**
     * Blur event for select.
     * @param {Object} values : filter values
     */
    onBlurList(values) {
      this.onBlur(this.getNormalizedValues(values));
    },
    /**
     * On Select open
     */
    onOpenList() {
      if (!this.options.length) {
        this.loadOptions();
      }
    },
    /**
     * Hook to load the options of the attribute
     *
     * @returns {Promise}
     */
    async loadOptions() {
      if (this.loadOptionsPromise) {
        return this.loadOptionsPromise;
      }

      if (!this.isComboListFilter) {
        return;
      }

      this.loadOptionsPromise = this._loadOptions();
      return this.loadOptionsPromise;
    },
    /**
     * Loads the options of a Select
     */
    async _loadOptions() {
      this.hasOptionsError = false;
      this.isLoadingOptions = true;

      try {
        this.options = [];

        const results = await this.loadOptionsFunction(this.filter, {});
        this.options = results || [];

        // If the computed linkValue length is different from the prop value length,
        // that means that some values were not found in our options
        // Emit the current value as the new value
        if (
          (!this.value && this.linkValue.length !== 0) ||
          (!!this.value &&
            this.linkValue.length !==
              (Array.isArray(this.value) ? this.value : [this.value]).length)
        ) {
          this.onListUpdated(this.linkValue);
        }
      } catch (err) {
        this.hasOptionsError = true;
        this.options = [];

        services
          .getService('alerts')
          .alertError(this.$t('common.filters.error.load_options.alert_error'));
      }
      this.isLoadingOptions = false;
      this.loadOptionsPromise = null;
    },
    /**
     * Change event for date filter
     * @param {String} startValue : Start value (date string)
     * @param {String} endValue : End value (date string)
     * @param {Boolean} startDateChanged : True if start date changed, otherwise end date changed
     */
    onDateChange(startValue, endValue, startDateChanged) {
      if (this.isRange === false) {
        this.onChange(startValue);
      } else {
        let value = [];
        if ((!startDateChanged || startValue) && (startDateChanged || endValue)) {
          // Verify dates consistency
          let startDate = new Date(startValue);
          let endDate = new Date(endValue);
          if (startDateChanged) {
            // Start date changed
            if (!endValue || startDate >= endDate) {
              endDate = new Date(startDate);
              endDate.setDate(startDate.getDate() + 1);
              endValue = Vue.filter('isoDate')(endDate);
            }
          } else {
            // End date changed
            if (!startValue || startDate >= endDate) {
              startDate = new Date(endDate);
              startDate.setDate(endDate.getDate() - 1);
              startValue = Vue.filter('isoDate')(startDate);
            }
          }

          value = [startValue, endValue];
        }

        this.onChange(value);
      }
    },
    /**
     * Number formatter (format function).
     * @param {Int} value : Value to format
     * @returns {String} the formatted value
     */
    numberFormatter(value) {
      if (this.attribute.options && this.attribute.options.format) {
        return getFormattingManager().getFormatter('Numeric')(value, this.attribute.options.format);
      }

      return value;
    },
    /**
     * Normalized values in parameters for select.
     * @returns {Array} the array of alias.
     */
    getNormalizedValues(values) {
      if (!(values instanceof Array)) {
        values = [values];
      }

      return values;
    },
    /**
     * Returns filter operator.
     */
    getFilterOperator() {
      return helpers.getHelper('filtersHelper').getFilterOperator(this.filter);
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    // For combo list, if we already have a value, we must load the options
    // to get the full option value object
    if (this.isComboListFilter && !!this.value) {
      this.loadOptions();
    }
  },
};
</script>
